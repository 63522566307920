import React from 'react';
import { Link } from "gatsby";

import NotFoundStyles from "./NotFound.module.scss";
import fourOhFourMain from "../images/background-main.jpg";


const NotFound = () => {
    return (
        <section className={NotFoundStyles.notFound}>
            <div className="inner-container">
                <h2 className="visually-hidden">404: The page you're looking for was not found.</h2>
                <figure>
                    <img src={fourOhFourMain} alt="404 Not Found" title="The page you're looking for was not found." />
                    <figcaption>404: Page not found.</figcaption>
                </figure>
                <p>Please try again or <Link to="/">return to the homepage</Link>.</p>
            </div>
        </section>
    );
};

export default NotFound;