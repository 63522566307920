import React from "react";
import Layout from "../components/Layout";

import Head from "../components/SiteHead";
import NotFound from "../components/NotFound";

const FourOhFour = () => {
    return (
        <Layout>
            <Head title="404 Not Found" />
            <NotFound />
        </Layout>
    );
};

export default FourOhFour;